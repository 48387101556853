import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-new-product"
export default class extends Controller {
  static targets = ["product_type",
                    "Superannuation",
                    "Retirement",
                    "Investment",
                    "Insurance",

                    "ProductName",
                    "ProductRecommendation",

                    "beneficiary_super",
                    "BeneficiarySuper",

                    "beneficiary_retirement",
                    "BeneficiaryRetirement",

                    "implementation_required",
                    "Implementation",

                    "recommendationAccepted",
                    "recommendationAcceptedDetails",

                    "recommendationHeader",
                    "recommendationHeaderLine"
                   ]

  connect() {
    console.log("NewProductController connected!");

    this.handleProductTypeChange();
    this.element.addEventListener("change", () => this.handleProductTypeChange());

    this.handleBeneficiarySuperChange();
    this.element.addEventListener("change", () => this.handleBeneficiarySuperChange());

    this.handleBeneficiaryRetirementChange();
    this.element.addEventListener("change", () => this.handleBeneficiaryRetirementChange());
    
    this.handleImplementationCheckboxChange();
    this.element.addEventListener("change", () => this.handleImplementationCheckboxChange());

    this.handleRecommendationAcceptedChange();
    this.element.addEventListener("change", () => this.handleRecommendationAcceptedChange());
  }

  handleBeneficiarySuperChange() {
    if (this.hasBeneficiary_superTarget && this.hasBeneficiary_superTarget) {
      const selectedBeneficiarySuperValue = this.beneficiary_superTarget.value;

      console.log(`Beneficiary Super: ${selectedBeneficiarySuperValue}`);

      // Perform logic based on the selectedBeneficiarySuperValue
      if (selectedBeneficiarySuperValue === "not_applicable" || selectedBeneficiarySuperValue === "") {
        this.BeneficiarySuperTarget.classList.add("hidden");
      } else {
        this.BeneficiarySuperTarget.classList.remove("hidden");
      }
    }
  }

  handleBeneficiaryRetirementChange() {
    if (this.hasBeneficiary_retirementTarget && this.hasBeneficiary_retirementTarget) {
      const selectedBeneficiaryRetirementValue = this.beneficiary_retirementTarget.value;

      console.log(`Beneficiary Retirement: ${selectedBeneficiaryRetirementValue}`);

      // Perform logic based on the selectedBeneficiaryRetirementValue
      if (selectedBeneficiaryRetirementValue === "not_applicable" || selectedBeneficiaryRetirementValue === "") {
        this.BeneficiaryRetirementTarget.classList.add("hidden");
      } else {
        this.BeneficiaryRetirementTarget.classList.remove("hidden");
      }
    }
  }  

  handleProductTypeChange() {
    if (this.hasProduct_typeTarget && this.hasSuperannuationTarget && this.hasRetirementTarget && this.hasInvestmentTarget && this.hasInsuranceTarget &&
      this.hasProductNameTarget && this.hasProductRecommendationTarget) {
      const selectedProductTypeValue = this.product_typeTarget.value;
      // Perform logic based on the selectedProductTypeValue
      if (selectedProductTypeValue === "Superannuation") {
        this.SuperannuationTarget.classList.remove("hidden");
        this.RetirementTarget.classList.add("hidden");
        this.InvestmentTarget.classList.add("hidden");
        this.InsuranceTarget.classList.add("hidden");
        this.ProductNameTarget.classList.remove("hidden");
        this.ProductRecommendationTarget.classList.remove("hidden");
        this.recommendationHeaderTarget.classList.remove("hidden");
        this.recommendationHeaderLineTarget.classList.remove("hidden");
      } else if (selectedProductTypeValue === "Retirement") {
        this.SuperannuationTarget.classList.add("hidden");
        this.RetirementTarget.classList.remove("hidden");
        this.InvestmentTarget.classList.add("hidden");
        this.InsuranceTarget.classList.add("hidden");
        this.ProductNameTarget.classList.remove("hidden");
        this.ProductRecommendationTarget.classList.remove("hidden");
        this.recommendationHeaderTarget.classList.remove("hidden");
        this.recommendationHeaderLineTarget.classList.remove("hidden");
      } else if (selectedProductTypeValue === "Investment") {
        this.SuperannuationTarget.classList.add("hidden");
        this.RetirementTarget.classList.add("hidden");
        this.InvestmentTarget.classList.remove("hidden");
        this.InsuranceTarget.classList.add("hidden");
        this.ProductNameTarget.classList.remove("hidden");
        this.ProductRecommendationTarget.classList.remove("hidden");
        this.recommendationHeaderTarget.classList.remove("hidden");
        this.recommendationHeaderLineTarget.classList.remove("hidden");
      } else if (selectedProductTypeValue === "Insurance") {
        this.SuperannuationTarget.classList.add("hidden");
        this.RetirementTarget.classList.add("hidden");
        this.InvestmentTarget.classList.add("hidden");
        this.InsuranceTarget.classList.remove("hidden");
        this.ProductNameTarget.classList.remove("hidden");
        this.ProductRecommendationTarget.classList.remove("hidden");
        this.recommendationHeaderTarget.classList.remove("hidden");
        this.recommendationHeaderLineTarget.classList.remove("hidden");
      } else {
        this.SuperannuationTarget.classList.add("hidden");
        this.RetirementTarget.classList.add("hidden");
        this.InvestmentTarget.classList.add("hidden");
        this.InsuranceTarget.classList.add("hidden");
        this.ProductNameTarget.classList.add("hidden");
        this.ProductRecommendationTarget.classList.add("hidden");
        this.recommendationHeaderTarget.classList.add("hidden");
        this.recommendationHeaderLineTarget.classList.add("hidden");
      }
    }
  }  

  handleImplementationCheckboxChange() {
    if (this.hasImplementation_requiredTarget && this.hasImplementationTarget) {
      const implementation_requiredValue = this.implementation_requiredTarget.value;

      console.log(`Implementation Required: ${implementation_requiredValue}`);

      // Perform logic based on the checkbox values
      if (implementation_requiredValue === 'true') {
        // Show 
        this.ImplementationTarget.classList.remove("hidden");
      } else {
        // Hide 
        this.ImplementationTarget.classList.add("hidden");
      }
    }
  } 

  handleRecommendationAcceptedChange() {
    if (this.hasRecommendationAcceptedTarget && this.hasRecommendationAcceptedDetailsTarget) {
      const recommendationAcceptedValue = this.recommendationAcceptedTarget.value;

      console.log(`Recommendation Accepted: ${recommendationAcceptedValue}`);

      // Perform logic based on the checkbox values
      if (recommendationAcceptedValue === 'yes_with_changes') {
        // Show
        this.recommendationAcceptedDetailsTarget.classList.remove("hidden");
      } else {
        // Hide
        this.recommendationAcceptedDetailsTarget.classList.add("hidden");
      }
    }
  }   

}
