import "../controllers"
import "chartkick/chart.js"

import "trix"
import "../trix_custom.js"

import "../custom_form_dependent_fields.js"
import "@rails/actiontext"
import "flowbite/dist/flowbite.turbo.js"

// Import the textarea auto-resize functionality
import { setupTextareaAutoResize } from '../textarea_auto_resize'

// Set up the textarea auto-resize functionality
setupTextareaAutoResize()

// Import the conditional fields functionality for form field definitions
import { setupConditionalFields } from '../conditional_select_field.js';

// Set up the conditional fields functionality
setupConditionalFields();

import '../pagination.js'
import "../submit_on_change.js"

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//

import * as Turbo from '@hotwired/turbo'

import Swal from 'sweetalert2'

const wrappedConfirm = async (message) => {
  console.log('wrappedConfirm called with message:', message)
  const {isConfirmed} = await Swal.fire({
    text: message,
    icon: 'warning',
    iconColor: '#dc2626',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    confirmButtonColor: '#dc2626',
    reverseButtons: true
  })
  
  return isConfirmed 
}

Turbo.setConfirmMethod(wrappedConfirm)
Turbo.start()

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'