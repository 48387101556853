import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-insurance-company"
export default class extends Controller {
  static targets = ["label", "toggleCheckbox", "checkbox"]

  connect() {
    this.toggle();
  }

  toggleChildren(e) {
    const insurance_companies = this.checkboxTargets;
    const label = this.labelTarget;

    if (e.target.checked) { // if the toggle checkbox is checked
      // filter out the insurance companies that are not checked
      const unselected_insurance_companies = insurance_companies.filter(insurance_company => !insurance_company.checked)

      // map through the unselected insurance companies and set checked to true
      unselected_insurance_companies.map(insurance_company => insurance_company.checked = true)

      // change the label to "Unselect All"
      label.textContent = "Unselect All";

    } else { // if the toggle checkbox is not checked
      // filter out the insurance companies that are checked
      const selected_insurance_companies = insurance_companies.filter(insurance_company => insurance_company.checked)
      
      // map through the selected insurance companies and set checked to false
      selected_insurance_companies.map(insurance_company => insurance_company.checked = false)

      // change the label to "Select All"
      label.textContent = "Select All";
    }
  }

  toggleParent() {
    this.toggle();
  }

  toggle() {
    const insurance_companies = this.checkboxTargets;
    const toggleCheckbox = this.toggleCheckboxTarget;
    const label = this.labelTarget;

    // check if there was a checkbox that was not checked
    if(insurance_companies.map(insurance_company => insurance_company.checked).includes(false)) {
      // true: uncheck the toggle checkbox
      // true: change the label to "Select All"
      toggleCheckbox.checked = false;
      label.textContent = "Select All";
    } else {
      // false: check the toggle checkbox
      // false: change the label to "Unselect All"
      toggleCheckbox.checked = true;
      label.textContent = "Unselect All";
    }
  }
}