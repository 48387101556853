import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="show-hide-strategy-trade-off"
export default class extends Controller {
  static targets = ["strategyTradeOffRequired", "strategyTradeOffDetails"];

  connect() {
    console.log("ShowHideStrategyTradeOff controller connected!");

    this.handleStrategyTradeOffRequiredChange();
    this.element.addEventListener("change", () => this.handleStrategyTradeOffRequiredChange());
  }

  handleStrategyTradeOffRequiredChange() {
    const strategyTradeOffRequiredValue = this.strategyTradeOffRequiredTarget.value;
    
    console.log(`Strategy Trade Off Required: ${strategyTradeOffRequiredValue}`);

    if (strategyTradeOffRequiredValue === "yes") {
      this.strategyTradeOffDetailsTarget.classList.remove("hidden");
    } else {
      this.strategyTradeOffDetailsTarget.classList.add("hidden");
    }
  }
}
