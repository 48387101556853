import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="client-contacts"
export default class extends Controller {
  static targets = [ "clientSelect", "clientContacts", "clientContactsCheckboxes" ]

  connect() {
    console.log("ClientContactsController connected");

    if (this.hasClientSelectTarget) {
      this.clientSelectChanged();
    }

    const clientId = this.element.dataset.clientId;
    console.log(`Client ID: ${clientId}`);

    if (clientId) {
      this.clientContactsTarget.classList.remove('hidden');
      this.loadClientContacts(clientId);
    }
  } 

  clientSelectChanged() {
    if (this.hasClientSelectTarget) {
      const clientId = this.clientSelectTarget.value;
      console.log(`Selected value: ${clientId}`);

      if (clientId) {
        this.clientContactsTarget.classList.remove('hidden');
        this.loadClientContacts(clientId);
      } else {
        this.clientContactsTarget.classList.add('hidden');
      } 
    }   
  } 

  loadClientContacts(clientId) {
    fetch(`/clients/${clientId}/contacts`)
      .then(response => response.json())
      .then(data => {
        const container = this.clientContactsCheckboxesTarget;
        container.innerHTML = '';
  
        // Parse persisted client contact IDs
        const persistedContactIds = this.element.dataset.clientContactsPersisted.split(',').map(id => parseInt(id));
  
        data.forEach(contact => {
          // Create a div to wrap each checkbox and label pair
          const wrapperDiv = document.createElement('div');
          wrapperDiv.classList.add('flex', 'items-center', 'mt-2');
  
          // Create a checkbox input
          const checkbox = document.createElement('input');
          checkbox.type = 'checkbox';
          checkbox.value = contact.id;
          checkbox.name = 'advice_case[client_contact_ids][]';
          checkbox.id = `client_contact_ids_${contact.id}`;
          checkbox.classList.add('form-checkbox', 'text-blue-600', 'h-4', 'w-4', 'rounded', 'mr-2');
  
          // Check the box if the contact ID is in the persistedContactIds array
          if (persistedContactIds.includes(contact.id)) {
            checkbox.checked = true;
          }
  
          // Create a label for the checkbox
          const label = document.createElement('label');
          label.htmlFor = `client_contact_ids_${contact.id}`;
          label.classList.add('text-gray-700');
          label.textContent = contact.name;
  
          // Append checkbox and label to the wrapper div
          wrapperDiv.appendChild(checkbox);
          wrapperDiv.appendChild(label);

          // Append the wrapper div to the container
          container.appendChild(wrapperDiv);
        });
      })
      .catch(error => console.error(error));
  }
  
}
